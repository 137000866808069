<template>
  <div class="container-list-services m-2 p-2">

    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 services position-relative h-100">
        <!-- Services Search Filters -->
<!--        <SearchServiceFilters class="search-service-filters"></SearchServiceFilters>-->
        <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 search-service-filters">
          <SearchServiceFilters @refreshServices="refreshServices"></SearchServiceFilters>
        </div>
        <!-- No Results -->
        <div class="fw-bold p-5 m-2" v-if="!pagination && noData">No results to show <br/>Try to amend your search criteria please</div>
        <!-- Loading -->
        <Loader class="mt-5" :loading="loading" :small="false"></Loader>
        <!-- Loop Services -->
        <div v-if="!loading && this.$store.state.services.services && this.$store.state.services.services.data" class="services-container">
          <div v-for="(service, index) in this.$store.state.services.services.data"
               :key="index"
          >
            <div :class="serviceId === service.id ? 'service-details p-2 active-service-details' : 'service-details p-2'"
                 :id="'service-'+service.id"
                 v-on:click="setActiveServiceThumbnail(service.id)"
            >

              <div class="row ps-3 m-0 text-start service-icons">
                <div class="col-1">
                  <ul class="list-unstyled">
                    <!-- Price -->
                    <li>
                      <span data-tooltip="tooltip">
                        <font-awesome-icon icon="pound-sign" class="icon-fa" />
                        <span class="tooltiptext">Price</span>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-7">
                  <ul class="list-unstyled w-100">
                    <!-- Price -->
                    <li class="list-relative">
                      <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                        <span class="price-color"> {{ convertNumberToCommas(service.price) }} </span>
                        <span v-if="service && service.service_payment_type" class="fw-bold"> per {{ service.service_payment_type.type }} </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-4">
                  <!-- Start Action Buttons -->
                  <div class="action-dropdown">
                    <button @click.stop="preventParentClick()" class="btn-action-dropdown" type="button" :id="'actionServicesDropdownList'+service.id" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                      <font-awesome-icon icon="ellipsis-h" style="color: #868e96;" />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end list-unstyled action-buttons" :aria-labelledby="'actionServicesDropdownList'+service.id">
                      <li class="action-item" @click.stop="toggleReportType(service.id)">
                        <button @click.stop="toggleReportType(service.id)"
                                type="button"
                                :id="'actionServicesDropdownReportType'+service.id"
                                aria-expanded="false"
                                :class="processing ? 'disabled action-button-report' : 'action-button-report'"
                        >
                          <font-awesome-icon icon="exclamation" class="min-width-30 icon-report" />
                          REPORT
                        </button>
                        <!-- Report Type Dropdown -->
                        <ul @mouseleave="toggleReportType(service.id)" class="dropdown-menu dropdown-menu-start list-unstyled action-buttons" :id="'actionServicesDropdownReportTypeMenu'+service.id" :aria-labelledby="'actionServicesDropdownReportType'+service.id">
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-spam' : 'action-button-spam'"
                                    @click.stop="reportAdvertise(service.id, 'Service', 'Spam')"
                            >
                              <font-awesome-icon icon="clone" class="min-width-30 icon-spam" />
                              SPAM
                            </button>
                          </li>
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-scam' : 'action-button-scam'"
                                    @click.stop="reportAdvertise(service.id, 'Service', 'Scam')"
                            >
                              <font-awesome-icon icon="user-ninja" class="min-width-30 icon-scam" />
                              SCAM
                            </button>
                          </li>
                          <li class="action-item">
                            <button :class="processing ? 'disabled action-button-abuse' : 'action-button-abuse'"
                                    @click.stop="reportAdvertise(service.id, 'Service', 'Abuse')"
                            >
                              <font-awesome-icon icon="heart-broken" class="min-width-30 icon-abuse" />
                              ABUSE
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="service-info-list-relative pt-1 pb-1">
                <div v-if="service.service_title" class="text-start ps-3 service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <span class="fw-bold">Title:</span>
                  {{ service.service_title }}
                </div>
              </div>
              <div class="service-info-list-relative pt-1 pb-1">
                <div v-if="service.address" class="text-start ps-3 service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                  <span class="fw-bold">Area Covered:</span>
                  {{
                    (service.address.county ? service.address.county : '') +
                    (service.address.city ? ', ' + service.address.city : '') +
                    (service.address.line_1 ? ', ' + service.address.line_1 : '') +
                    (service.address.postcode ? ', ' + service.address.postcode : '')
                  }}
                </div>
              </div>
              <div class="row service-info m-0 pt-1 pb-1 ps-3 text-start">
                <div class="col-6 m-0 p-0 text-start">
                  <span class="fw-bold">Posted:</span>
                  {{ calculateCreatedDate(service.created_at) }}
                </div>
                <div class="col-6 m-0 p-0 text-center position-relative">
                  <div class="text-center service-info text-truncate-item no-text-wrap overflow-hidden text-truncate">
                    <span class="fw-bold">{{ service.occupation ? service.occupation.type.toUpperCase() : '' }}</span>
                  </div>
                </div>
              </div>
              <div class="row service-thumbnails">
                <div class="col-8">
                  <div :id="'carouselCaptions-'+index" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">
                      <!--                    <div v-if="service.is_cash" class="cash-caption d-none d-md-block">-->
                      <!--                      <p>Cash Only</p>-->
                      <!--                    </div>-->
                      <!--                    <div v-if="service.is_auction" class="auction-caption d-none d-md-block">-->
                      <!--                      <p>Auction</p>-->
                      <!--                    </div>-->
                      <div v-if="service.documents_service_images.length === 0">
                        <img :src="require(`@/assets/no-image-found.png`)"
                             class="d-block w-100 img-fluid thumbnails-height"
                             alt="..."
                        >
                      </div>
                      <div v-for="(doc, num) in service.documents_service_images" :key="num" :class="num === 0 ? 'active carousel-item' : 'carousel-item'">
                        <img :src="`${$s3bucket}${doc.file_path}`" @error="setDefaultImg"
                             class="d-block w-100 img-fluid thumbnails-img"
                             alt="..."
                        >
<!--                        <img v-if="getImage(doc.file_name)" :src="require(`@/assets/${doc.file_name}`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
<!--                        <img v-else :src="require(`@/assets/no-image-found.png`)" class="d-block w-100 img-fluid thumbnails-img" alt="...">-->
                      </div>
                    </div>

                    <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="prev" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" :data-bs-target="'#carouselCaptions-'+index" data-bs-slide="next" v-on:click.stop="preventParentClick()">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>

                  </div>
                </div>
                <div class="col-4 p-0 m-0 service-details-section">
<!--                  <div class="row p-0 m-0 text-start service-icons">-->
<!--                    <div class="col-3">-->
<!--                      <ul class="list-unstyled">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li>-->
<!--                      <span data-tooltip="tooltip">-->
<!--                        <font-awesome-icon icon="pound-sign" class="icon-fa" />-->
<!--                        <span class="tooltiptext">Price</span>-->
<!--                      </span>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                    <div class="col-8">-->
<!--                      <ul class="list-unstyled w-100">-->
<!--                        &lt;!&ndash; Price &ndash;&gt;-->
<!--                        <li class="list-relative">-->
<!--                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">-->
<!--                            <span class="price-color"> {{ convertNumberToCommas(service.price) }} </span>-->
<!--                            <span v-if="service && service.service_payment_type" class="fw-bold"> per {{ service.service_payment_type.type }} </span>-->
<!--                          </div>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </div>-->
<!--                  </div>-->

                </div>
              </div>

            </div>
            <!-- Start AdSense -->
            <div v-if="(index !== 0 && index % 4 === 0) || checkIfLastIndex(index)">
              <AdSense></AdSense>
            </div>
            <!-- End AdSense -->

          </div>
        </div>
        <!-- End Loop Services -->

        <!-- Pagination -->
        <ul class="pagination justify-content-center" v-if="pagination && !loading">
          <li class="page-item" :class="{disabled: metaData.current_page === 1}">
            <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
          </li>
          <li
              v-for="number in paginationData"
              class="page-item"
              :class="{active: metaData.current_page === number}"
          >
            <a class="page-link" @click="navigateTo(null, number)">{{ number }}</a>
          </li>
          <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
            <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
          </li>
        </ul>

      </div>

      <!-- AdService Section -->
      <div class="col-12 col-lg-8 col-md-12 col-sm-12 col-xs-12" id="adContainer">
        <div class="position-relative h-100">
          <div id="adServiceContainer" class="ad-service-container no-scroll"> <!-- modal-tab -->
            <!-- Loading -->
            <div class="fw-bold p-5 m-2" v-if="loading">Please choose service advertise</div>
            <Loader :loading="loading" :small="false"></Loader>
            <AdService v-if="!loading" :id="serviceId"></AdService>
          </div>
        </div>
      </div>
      <!-- End AdService Section -->
    </div>

  </div>
</template>

<script>
import axios from "axios"
import {mapActions} from "vuex";
import AdService from './AdService'
import Loader from "./Loader.vue"
import SearchServiceFilters from "./SearchServiceFilters.vue"
import AdSense from "./AdSense"

export default {
  name: 'Services',
  components: {
    'AdService': AdService,
    'Loader': Loader,
    'SearchServiceFilters': SearchServiceFilters,
    'AdSense': AdSense
  },
  props: {
  },
  data(){
    return {
      defaultImg: require('@/assets/no-image-found.png'),
      serviceId: null,
      pagination: false,
      metaData: '',
      linksData: '',
      paginationData: '',
      loading: false,
      noData: false,
      form: '',
      serviceTypeOccupation: '',
      processing: true,
      validationErrors: null
    }
  },
  async mounted() {
    // get search services filters if present
    let searchServicesFilters = null;
    if (this.$store.state.searchServicesFilters.searchServicesFilters !== false) {
      searchServicesFilters = this.$store.state.searchServicesFilters.searchServicesFilters;
    }
    await this.getServices(searchServicesFilters);

    if (this.$store.state.services.services &&
        this.$store.state.services.services.data &&
        this.$store.state.services.services.data.length > 0
    ) {
      this.loading = false;
      this.noData = false;
      this.pagination = true;
      if (this.pagination) {
        this.calculatePagination();
      }
    } else {
      this.loading = false;
      this.noData = true;
      this.pagination = false;
    }
  },
  methods: {
    ...mapActions({
      setSearchServicesFilters:'searchServicesFilters/setSearchServicesFilters'
    }),
    ...mapActions({
      getServicesState:'services/getServices',
      getServicesMapState:'services/getServicesMap'
    }),
    refreshServices(searchServicesFilters) {
      this.getServices(searchServicesFilters);
    },
    async getServices(servicesFilters = null) {
      this.processing = true

      // Temporarily as we expect some address or drawbbox on search
      if (servicesFilters === null) {
        servicesFilters = {
          address: 'London, Greater London, England, United Kingdom',
          latitude: '51.507321899999994',
          longitude: '-0.12764739999999997',
          min_price: '',
          max_price: '',
          price: 'Price',
          service_payment_type_id: '',
          checked: false,
          occupation: ''
        }
      }

      // Set searchServicesFilters in Vuex state
      this.setSearchServicesFilters(servicesFilters);

      await this.getServicesState(servicesFilters);
      await this.getServicesMapState(servicesFilters);
      this.processing = false
    },
    navigateTo(url, page = null) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.loading = true;
      if (page) {
        url = this.linksData.first.substring(0, this.linksData.first.lastIndexOf('=') + 1) + page;
      }
      axios.post(url, this.$store.state.searchServicesFilters.searchServicesFilters).then(({data})=>{
        // I should really trigger setProperties from vuex
        this.$store.state.services.services = data;
        this.calculatePagination();
      }).catch(({response})=>{
        alert(response.data.message)
      }).finally(()=> {
        this.processing = false;
        this.loading = false;
      })
    },
    previous() {
      if (this.linksData.prev) {
        this.navigateTo(this.linksData.prev)
      }
    },
    next() {
      if (this.linksData.next) {
        this.navigateTo(this.linksData.next)
      }
    },
    getImage(file_name) {
      try {
        return require(`@/assets/${file_name}`);
      } catch (e) {
        return false
      }
    },
    setDefaultImg(e) {
      e.target.src = require(`@/assets/no-image-found.png`);
    },
    toggleReportType(id) {
      let el = document.getElementById("actionServicesDropdownReportTypeMenu"+id);
      if (el && el.classList.contains('showReportTypeMenu')) {
        el.classList.toggle('showReportTypeMenu');
      } else if (el && !el.classList.contains('showReportTypeMenu')) {
        el.classList.toggle('showReportTypeMenu');
      }
    },
    async reportAdvertise(id, type, report_type) {

      if (!confirm("Are you sure you want to report this advertise?")) {
        return;
      }
      this.processing = true;

      // Create form: type should be either Property or Service
      let requestForm = new FormData();
      requestForm.append("id", id);
      requestForm.append("type", type);
      requestForm.append("report_type", report_type);

      await axios.post("api/report/", requestForm).then(response => {
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          // this.validationMessage = data.message;
        }
      }).finally(() => {
        this.processing = false

        // Refresh user data so we get new favourites in the listing
        if (this.validationErrors === null) {
          this.$root.$emit('refreshUserServices', null, this.$store.state.auth.user.id, 15);
        }
      })
    },
    setActiveServiceThumbnail(id) {
      this.serviceId = id;
      let screenWidth = window.innerWidth;
      if (screenWidth <= 991) {
        let adPreview = document.getElementById('adContainer');
        if (adPreview) {
          adPreview.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
      }
    },
    preventParentClick() {
      // Do nothing, its just used to prevent parent function trigger (setActiveServiceThumbnail)
    },
    checkIfLastIndex(index){
      return index === this.$store.state.services.services.data.length - 1;
    },
    calculateCreatedDate(date) {
      let resultDate = null;

      let currentDate = new Date();
      let propDate = new Date(date);
      let numberOfDays = (currentDate.getTime() - propDate.getTime()) / (1000*60*60*24);

      switch (true) {
        case numberOfDays <= 1:
          resultDate = "Today";
          break;
        case (numberOfDays <= 30 && numberOfDays > 1):
          if(numberOfDays < 2) {
            resultDate = Math.round(numberOfDays)+" day ago";
          } else {
            resultDate = Math.round(numberOfDays)+" days ago";
          }
          break;
        case numberOfDays > 30:
          let numberOfMonths = Math.round(numberOfDays / 30);
          if(numberOfMonths === 1) {
            resultDate = numberOfMonths+" month ago";
          } else {
            resultDate = numberOfMonths+" months ago"
          }
          break;
      }

      return resultDate;

    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    calculatePagination() {
      if(this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    }
  },
  created: function () {

  },
  watch: {
    '$store.state.services.loading': function() {
      this.loading = this.$store.state.services.loading;
    },
    '$store.state.services.services': function() {
      this.metaData = this.$store.state.services.services.meta;
      this.linksData = this.$store.state.services.services.links;
      if (
          this.$store.state.services.services &&
          this.$store.state.services.services.data &&
          this.$store.state.services.services.data.length > 0
      ) {
        this.noData = false;
        this.pagination = true;
        this.calculatePagination();
      } else {
        this.noData = true;
        this.pagination = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Start Text truncate - white space - for long texts and icons */
.service-info-list-relative {
  width: 100%;
  min-height: 30px;
  position: relative;
}

.list-relative {
  width: 100%;
  position: relative;
}

.text-truncate-item {
  width: 100%;
  box-sizing: border-box;
  transition: 0.7s;
  border-radius: 5px;
}

.text-truncate-item:hover {
  position: absolute;
  transition: 0.7s;
  box-sizing: border-box;
  overflow: visible !important;
  white-space: normal !important;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0 0 0 1px;
  z-index: 1000;
}
/* End Text truncate - white space - for long texts and icons */

/* Services Search Filters */
.search-service-filters {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  height: 18vh;
  z-index: 1002;
  padding-top: 5px;
  margin: 5px 0 5px 0;
  top: 10.5vh;
}
/* End Services Search Filters */

/* Start Action Buttons */
.services .action-dropdown {
  z-index: 9999;
  min-height: 30px;
  text-align: end;
  background-color: rgba(255, 255, 255 , 0);
  color: #868e96;
  cursor: pointer;

  .dropdown-menu {
    //top: unset;
    //right: 5%;
  }
  .btn-action-dropdown {
    border: none;
    border-radius: 50%;
    background-color: #e9ecef;
    cursor: pointer;
  }
  .btn-action-dropdown:hover {
    cursor: pointer;
  }
}

.action-dropdown:hover {
  cursor: pointer;
}

.action-buttons {
  z-index: 1001;
  .action-item {
    padding: 2px 5px 2px 5px;
    margin: 2px 5px 2px 5px;
    min-width: 80px;
    font-size: 13px;
  }

  .action-button-report {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-report:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-report:hover .icon-report {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-spam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-spam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-spam:hover .icon-spam {
    color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-scam {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-scam:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-scam:hover .icon-scam {
    color: mediumblue;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }

  .action-button-abuse {
    text-align: start;
    min-height: 30px;
    width: 100%;
    background-color: unset;
    border: none;
    //border-bottom: 3px solid deepskyblue;
    min-width: 80px;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1);
  }

  .action-button-abuse:hover {
    background-color: unset;
    border: none;
    transition: 0.5s;
    -moz-transition: 0.5s;
    transform: scale(1.1);
  }

  .action-button-abuse:hover .icon-abuse {
    color: red;
    transition: 0.5s;
    -moz-transition: 0.5s;
  }
}

.showReportTypeMenu {
  display: block;
  right: 0 !important;
  top: 100% !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
}

/* End Action Buttons */

.services {
  .service-details {
    background-color: #f1f1f1;
    border-radius: 15px;
    transition: 0.5s;
    margin: 5px 0px 5px 0px;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
  .service-details:hover {
    cursor: pointer;
  }
  .active-service-details {
    background-color: white;
    transition: 0.5s;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  }
}

.ad-service-container {
  border: 1px solid rgba(0,0,0,.125);
  margin: 15px 0 5px 0;
  height: 85vh;
  width: 65vw;
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 11.5vh;
  left: 32vw;
  //background-color: rgba(33, 150, 243, 50%);
  //background-color: rgba(255, 255, 255, 70%);
  overflow-x: hidden;
  transition: 0.5s;
  border-radius: 10px;
  background-clip: border-box;
}

.ad-service-container a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.ad-service-container a:hover {
  color: #818080;
}

.service-info {
  cursor: pointer;
  font-size: 13px;
}

.service-icons {
  --bs-gutter-x: 0;
  ul {
    margin: 0;
  }
}

.custom-col-length {
  .col-3 {
    width: 50% !important;
  }
}

/*
  START
  change some css so that images are always sized same
  use media queries to adjust that for mobile and other devices
*/

.service-thumbnails {
  .thumbnails-img {
    width: 100%;
    cursor: pointer;
    height: auto;
    max-height: 200px;
    min-height: auto;
    //max-height: 200px !important;
    //min-height: 200px !important;
  }
  .carousel-inner{
    width:100%;
    border-radius: 20px;
    .auction-caption {
      background-color: white;
      text-align: left;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 100% 5%;
      padding: 5px 5px 5px 10px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      left: -1px;
      z-index: 10;
    }
    .cash-caption {
      background-color: white;
      text-align: right;
      min-width: 80px;
      max-width: 80px;
      min-height: 25px;
      max-height: 25px;
      border-radius: 5% 5% 5% 100%;
      padding: 5px 3px 5px 5px;
      font-size: 11px;
      position: absolute;
      top: -1px;
      bottom: auto;
      right: 0;
      z-index: 10;
    }
  }
  .service-details-section {
    .col-8 {
      width: 72% !important;
    }
  }
  .service-details-section *:hover {
    cursor: pointer !important;
  }
}

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
}
/* end tooltip - popups info on icons */

.pagination {
  cursor: pointer;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 500px) {
  #adServiceContainer {
    width: 100% !important;
  }
  .search-service-filters {
    height: 12vh !important;
  }
  .services-container {
    margin-top: 15%;
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 501px) {
  #adServiceContainer {
    width: 100% !important;
  }
  .search-service-filters {
    height: 12vh !important;
  }
  .services-container {
    margin-top: 8%;
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 769px) {
  #adServiceContainer {
    width: 100% !important;
  }
  .search-service-filters {
    height: 9vh !important;
  }
  .services-container {
    height: 65vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 992px) {
  #adServiceContainer {
    width: 100% !important;
  }
  .search-service-filters {
    height: 14vh !important;
  }
  .services-container {
    height: inherit;
    //overflow-x: unset;
    //overflow-y: unset;
  }
}
@media screen and (min-width: 1200px) {
  #adServiceContainer {
    width: 65vw !important;
  }
}
@media screen and (min-width: 1920px) {

}


</style>
