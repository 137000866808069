<template>
  <div>
    <transition name="fade" v-show="activeServiceSearch">
      <div id="searchServiceModal" class="search-service-component">
        <div id="searchBarBox" class="search-bar-box" tabindex="-1">
          <form action="javascript:void(0)" method="post" enctype="multipart/form-data"> <!-- class="d-flex" -->
            <div class="form-position">
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-0 m-0">
                  <router-link v-if="this.$store.state.auth.user.contact && this.$store.state.auth.user.contact.email && this.$store.state.auth.authenticated === true" to="/add-edit-service" class="text-decoration-none">
                    <!--                      <button class="btn btn-success float-start addbtn" v-on:click="closePropModal"> + Add</button>-->
                    <button class="btn btn-outline-primary float-start btn-add addbtn w-100" type="button">Add <font-awesome-icon icon="plus-circle" class="icon-fa add-icon" /></button>
                  </router-link>
                </div>
                <div class="col-8 col-sm-6 col-md-6 col-lg-6 p-0 m-0 border-lr border-radius-l-5">
                  <div class="search-service-bar">
                    <div class="" id="geocoderSearchServiceFilters"></div>
                  </div>
                </div>
<!--                <div class="col-6 col-sm-2 col-md-2 col-lg-2 p-0 m-0 border-lr">-->
<!--                  &lt;!&ndash; #RAF Experiment later on with this class searchformfld &ndash;&gt;-->
<!--                  <select-->
<!--                      v-model="searchForm.ad_type"-->
<!--                      class="select-placeholder search-filter-btns dropdown-btn text-center"-->
<!--                      @focus="handleFocus($event)"-->
<!--                      @focusout="handleFocusOut(searchForm.ad_type, $event)"-->
<!--                      tabindex="-1"-->
<!--                      aria-label="ad_type"-->
<!--                      name="ad_type"-->
<!--                      id="ad_type"-->
<!--                  >-->
<!--                    <option value="" disabled>Ad Type</option>-->
<!--                    <option :value="1">Buy</option> &lt;!&ndash; Buy searches for Sell &ndash;&gt;-->
<!--                    <option :value="2">Rent</option> &lt;!&ndash; Rent searches for Let &ndash;&gt;-->
<!--                    <option :value="3">Buy & Rent</option> &lt;!&ndash; Buy and Rent searches for Sell and Let &ndash;&gt;-->
<!--                    <option :value="4">Sell</option> &lt;!&ndash; Sell searches for Buy &ndash;&gt;-->
<!--                    <option :value="5">Let</option> &lt;!&ndash; Let searches for Rent &ndash;&gt;-->
<!--                    <option :value="6">Sell & Let</option> &lt;!&ndash; Sell and Let searches for Buy and Rent &ndash;&gt;-->
<!--                  </select>-->
<!--&lt;!&ndash;                  <label for="ad_type">Ad Type</label>&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="col-2 p-0 m-0 border-lr">-->
<!--                  <select-->
<!--                      v-model="searchForm.radius"-->
<!--                      class="select-placeholder search-filter-btns"-->
<!--                      @focus="handleFocus($event)"-->
<!--                      @focusout="handleFocusOut(searchForm.radius, $event)"-->
<!--                      tabindex="-1"-->
<!--                      aria-label="radius"-->
<!--                      name="radius"-->
<!--                      id="radius"-->
<!--                  >-->
<!--                    <option value="" disabled>Radius</option>-->
<!--                    <option :value="1">+0 miles</option>-->
<!--                    <option :value="2">+1 mile</option>-->
<!--                    <option :value="3">+3 miles</option>-->
<!--                    <option :value="4">+5 miles</option>-->
<!--                    <option :value="5">+10 miles</option>-->
<!--                    <option :value="6">+15 miles</option>-->
<!--                    <option :value="7">+20 miles</option>-->
<!--                    <option :value="8">+30 miles</option>-->
<!--                    <option :value="9">+40 miles</option>-->
<!--                    <option :value="10">+50 miles</option>-->
<!--                  </select>-->
<!--                </div>-->
                <div class="col-4 col-sm-6 col-md-6 col-lg-6 p-0 m-0 border-lr">
                  <div class="dropdown-container-relative">
                    <button
                        type="button"
                        id="dropdownPrice"
                        class="search-filter-btns select-placeholder dropdown-toggle dropdown-btn"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                        @focus="handleFocus($event)"
                        @focusout="handleFocusOut(searchForm.min_price ? searchForm.min_price : searchForm.max_price, $event)"
                    >
                      {{ searchForm.price !== 'Price' ? convertNumberToCommas(searchForm.price) : searchForm.price }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-filters" aria-labelledby="dropdownPrice">
                      <div class="row m-0 p-0">
                        <div class="col-6 m-0 p-1">
                          <select
                              v-model="searchForm.min_price"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.min_price, $event)"
                              tabindex="-1"
                              aria-label="min_price"
                              name="min_price"
                              id="min_price"
                          >
                            <option value="" disabled>Min Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-6 m-0 p-1">
                          <select
                              v-model="searchForm.max_price"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.max_price, $event)"
                              tabindex="-1"
                              aria-label="max_price"
                              name="max_price"
                              id="max_price"
                          >
                            <option value="" disabled>Max Price</option>
                            <option :value="0">£0</option>
                            <option :value="50">£50</option>
                            <option :value="100">£100</option>
                            <option :value="150">£150</option>
                            <option :value="200">£200</option>
                            <option :value="250">£250</option>
                            <option :value="500">£500</option>
                            <option :value="750">£750</option>
                            <option :value="1000">£1,000</option>
                            <option :value="1250">£1,250</option>
                            <option :value="1500">£1,500</option>
                            <option :value="1750">£1,750</option>
                            <option :value="2000">£2,000</option>
                            <option :value="3000">£3,000</option>
                            <option :value="4000">£4,000</option>
                            <option :value="5000">£5,000</option>
                            <option :value="10000">£10,000</option>
                            <option :value="20000">£20,000</option>
                            <option :value="30000">£30,000</option>
                            <option :value="40000">£40,000</option>
                            <option :value="50000">£50,000</option>
                            <option :value="60000">£60,000</option>
                            <option :value="70000">£70,000</option>
                            <option :value="80000">£80,000</option>
                            <option :value="90000">£90,000</option>
                            <option :value="100000">£100,000</option>
                            <option :value="110000">£110,000</option>
                            <option :value="120000">£120,000</option>
                            <option :value="130000">£130,000</option>
                            <option :value="140000">£140,000</option>
                            <option :value="150000">£150,000</option>
                            <option :value="160000">£160,000</option>
                            <option :value="170000">£170,000</option>
                            <option :value="180000">£180,000</option>
                            <option :value="190000">£190,000</option>
                            <option :value="200000">£200,000</option>
                            <option :value="210000">£210,000</option>
                            <option :value="220000">£220,000</option>
                            <option :value="230000">£230,000</option>
                            <option :value="240000">£240,000</option>
                            <option :value="250000">£250,000</option>
                            <option :value="260000">£260,000</option>
                            <option :value="270000">£270,000</option>
                            <option :value="280000">£280,000</option>
                            <option :value="290000">£290,000</option>
                            <option :value="300000">£300,000</option>
                            <option :value="310000">£310,000</option>
                            <option :value="320000">£320,000</option>
                            <option :value="330000">£330,000</option>
                            <option :value="340000">£340,000</option>
                            <option :value="350000">£350,000</option>
                            <option :value="360000">£360,000</option>
                            <option :value="370000">£370,000</option>
                            <option :value="380000">£380,000</option>
                            <option :value="390000">£390,000</option>
                            <option :value="400000">£400,000</option>
                            <option :value="410000">£410,000</option>
                            <option :value="420000">£420,000</option>
                            <option :value="430000">£430,000</option>
                            <option :value="440000">£440,000</option>
                            <option :value="450000">£450,000</option>
                            <option :value="460000">£460,000</option>
                            <option :value="470000">£470,000</option>
                            <option :value="480000">£480,000</option>
                            <option :value="490000">£490,000</option>
                            <option :value="500000">£500,000</option>
                            <option :value="550000">£550,000</option>
                            <option :value="600000">£600,000</option>
                            <option :value="650000">£650,000</option>
                            <option :value="700000">£700,000</option>
                            <option :value="750000">£750,000</option>
                            <option :value="800000">£800,000</option>
                            <option :value="850000">£850,000</option>
                            <option :value="900000">£900,000</option>
                            <option :value="950000">£950,000</option>
                            <option :value="1000000">£1,000,000</option>
                            <option :value="1250000">£1,250,000</option>
                            <option :value="1500000">£1,500,000</option>
                            <option :value="1750000">£1,750,000</option>
                            <option :value="2000000">£2,000,000</option>
                            <option :value="2250000">£2,250,000</option>
                            <option :value="2500000">£2,500,000</option>
                            <option :value="2750000">£2,750,000</option>
                            <option :value="3000000">£3,000,000</option>
                            <option :value="3250000">£3,250,000</option>
                            <option :value="3500000">£3,500,000</option>
                            <option :value="3750000">£3,750,000</option>
                            <option :value="4000000">£4,000,000</option>
                            <option :value="4250000">£4,250,000</option>
                            <option :value="4500000">£4,500,000</option>
                            <option :value="4750000">£4,750,000</option>
                            <option :value="5000000">£5,000,000</option>
                            <option :value="7500000">£7,500,000</option>
                            <option :value="10000000">£10,000,000</option>
                            <option :value="15000000">£15,000,000</option>
                            <option :value="20000000">£20,000,000</option>
                          </select>
                        </div>
                        <div class="col-12 m-0 p-1">
                          <select
                              v-model="searchForm.service_payment_type_id"
                              class="select-placeholder search-filter-btns dropdown-btn cursor-pointer"
                              @focus="handleFocus($event)"
                              @focusout="handleFocusOut(searchForm.service_payment_type_id, $event)"
                              tabindex="-1"
                              aria-label="service_payment_type_id"
                              name="service_payment_type_id"
                              id="service_payment_type_id"
                          >
                            <option value="" disabled>Frequency</option>
                            <option :value="1">Not Applicable (N/A)</option>
                            <option :value="2">Per Hour</option>
                            <option :value="3">Per Night</option>
                            <option :value="4">Per Week</option>
                            <option :value="5">Per Month</option>
                            <option :value="6">Per Job</option>
                            <option :value="7">Per Square Meter</option>
                            <option :value="8">To Be Discussed (TBD)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
<!--                <div class="col-6 col-sm-3 col-md-3 col-lg-3 p-0 m-0 border-lr">-->
<!--                  <v-select-->
<!--                      v-model="searchForm.occupation"-->
<!--                      name="occupation"-->
<!--                      :options="serviceTypeOccupationsOptions"-->
<!--                      item-text="type"-->
<!--                      item-value="id"-->
<!--                      label="type"-->
<!--                      placeholder="Start typing occupation name..."-->
<!--                      class="select-placeholder"-->
<!--                  ></v-select>-->
<!--                </div>-->
              </div>
              <div class="row m-0 p-0 border-radius-5">
                <div class="col-10 col-sm-11 col-md-11 col-lg-11 p-0 m-0 border-lr border-radius-l-5">
                  <v-select
                      v-model="searchForm.occupation"
                      name="occupation"
                      :options="serviceTypeOccupationsOptions"
                      item-text="type"
                      item-value="id"
                      label="type"
                      placeholder="Start typing occupation name..."
                      class="select-placeholder"
                  ></v-select>
                </div>
                <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-0 m-0 border-lr dropdown-btn border-radius-r-5">
                  <button id="searchServicesFiltersButton" class="btn btn-outline-primary" v-on:click.prevent="searchServices" type="submit"><font-awesome-icon icon="search-location" class="icon-fa" /></button>
                </div>
              </div>
            </div>
          </form>
          <div id="filtersContainer"> <!-- btn btn-outline-success btn-round-search-filters      <br/><font-awesome-icon icon="chevron-down" id="serviceFilterIconChevronTwo" class="m-0 p-0" /> -->
            <button id="serviceFiltersButton" class="btn-outline-success btn-round-search-filters" v-on:click="activeServiceFilters = !activeServiceFilters"><font-awesome-icon icon="angle-double-down" id="serviceFilterIcon" class="m-0 p-0" /></button>
          </div>
        </div>
        <transition name="fade" v-if="activeServiceFilters">
          <div id="openServiceFilters" class="openServiceFilters scroll-width-thin" tabindex="-1">
            <div id="filters-service-form" class="filters-service-form">
            <form class="">
              <div class="row m-0 p-0 text-start">

                <!-- Start Main input fields -->
                <div class="row m-0 p-0">

                  <!-- Start Horizontal Line with Icon -->
                  <div v-if="!searchForm.occupation || noFiltersForOccupation" class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <div v-if="!searchForm.occupation || noFiltersForOccupation" class="m-2 p-2 fw-bold text-center">
                    Please choose occupation to see more advanced filters.
                    Some occupations might have additional filters, some other won't.
                  </div>

                  <!-- Start Horizontal Line with Icon -->
                  <div class="horizontal-separator-box">
                    <span class="hr-line-short"></span>
                    <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                    <span class="hr-line-short"></span>
                  </div>
                  <!-- End Horizontal Line with Icon -->

                  <!-- Minimum - Maximum Values -->
                  <div v-if="searchForm.occupation && searchForm.occupation.type === 'Lender'" class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-2">
                        <div class="list-relative">
                              <font-awesome-icon icon="pound-sign" class="icon-fa ms-1 me-1" />
                              <p class="options-paragraph m-0 p-0 me-2 d-inline">Loan Amount</p>
                        </div>
                      </div>
                      <div class="col-8 col-sm-8 col-md-8 col-lg-8 pt-1">
                        <div class="row m-0 p-0">
                          <div class="col-6 m-0 p-0 pe-1">
                            <select
                                v-model="searchForm.minimum"
                                class="select-placeholder filter-select cursor-pointer"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(searchForm.minimum, $event)"
                                tabindex="-1"
                                aria-label="minimum"
                                name="minimum"
                                id="minimum"
                            >
                              <option value="" disabled>Minimum Loan</option>
                              <option :value="0">£0</option>
                              <option :value="50">£50</option>
                              <option :value="100">£100</option>
                              <option :value="150">£150</option>
                              <option :value="200">£200</option>
                              <option :value="250">£250</option>
                              <option :value="500">£500</option>
                              <option :value="750">£750</option>
                              <option :value="1000">£1,000</option>
                              <option :value="1250">£1,250</option>
                              <option :value="1500">£1,500</option>
                              <option :value="1750">£1,750</option>
                              <option :value="2000">£2,000</option>
                              <option :value="3000">£3,000</option>
                              <option :value="4000">£4,000</option>
                              <option :value="5000">£5,000</option>
                              <option :value="10000">£10,000</option>
                              <option :value="20000">£20,000</option>
                              <option :value="30000">£30,000</option>
                              <option :value="40000">£40,000</option>
                              <option :value="50000">£50,000</option>
                              <option :value="60000">£60,000</option>
                              <option :value="70000">£70,000</option>
                              <option :value="80000">£80,000</option>
                              <option :value="90000">£90,000</option>
                              <option :value="100000">£100,000</option>
                              <option :value="110000">£110,000</option>
                              <option :value="120000">£120,000</option>
                              <option :value="130000">£130,000</option>
                              <option :value="140000">£140,000</option>
                              <option :value="150000">£150,000</option>
                              <option :value="160000">£160,000</option>
                              <option :value="170000">£170,000</option>
                              <option :value="180000">£180,000</option>
                              <option :value="190000">£190,000</option>
                              <option :value="200000">£200,000</option>
                              <option :value="210000">£210,000</option>
                              <option :value="220000">£220,000</option>
                              <option :value="230000">£230,000</option>
                              <option :value="240000">£240,000</option>
                              <option :value="250000">£250,000</option>
                              <option :value="260000">£260,000</option>
                              <option :value="270000">£270,000</option>
                              <option :value="280000">£280,000</option>
                              <option :value="290000">£290,000</option>
                              <option :value="300000">£300,000</option>
                              <option :value="310000">£310,000</option>
                              <option :value="320000">£320,000</option>
                              <option :value="330000">£330,000</option>
                              <option :value="340000">£340,000</option>
                              <option :value="350000">£350,000</option>
                              <option :value="360000">£360,000</option>
                              <option :value="370000">£370,000</option>
                              <option :value="380000">£380,000</option>
                              <option :value="390000">£390,000</option>
                              <option :value="400000">£400,000</option>
                              <option :value="410000">£410,000</option>
                              <option :value="420000">£420,000</option>
                              <option :value="430000">£430,000</option>
                              <option :value="440000">£440,000</option>
                              <option :value="450000">£450,000</option>
                              <option :value="460000">£460,000</option>
                              <option :value="470000">£470,000</option>
                              <option :value="480000">£480,000</option>
                              <option :value="490000">£490,000</option>
                              <option :value="500000">£500,000</option>
                              <option :value="550000">£550,000</option>
                              <option :value="600000">£600,000</option>
                              <option :value="650000">£650,000</option>
                              <option :value="700000">£700,000</option>
                              <option :value="750000">£750,000</option>
                              <option :value="800000">£800,000</option>
                              <option :value="850000">£850,000</option>
                              <option :value="900000">£900,000</option>
                              <option :value="950000">£950,000</option>
                              <option :value="1000000">£1,000,000</option>
                              <option :value="1250000">£1,250,000</option>
                              <option :value="1500000">£1,500,000</option>
                              <option :value="1750000">£1,750,000</option>
                              <option :value="2000000">£2,000,000</option>
                              <option :value="2250000">£2,250,000</option>
                              <option :value="2500000">£2,500,000</option>
                              <option :value="2750000">£2,750,000</option>
                              <option :value="3000000">£3,000,000</option>
                              <option :value="3250000">£3,250,000</option>
                              <option :value="3500000">£3,500,000</option>
                              <option :value="3750000">£3,750,000</option>
                              <option :value="4000000">£4,000,000</option>
                              <option :value="4250000">£4,250,000</option>
                              <option :value="4500000">£4,500,000</option>
                              <option :value="4750000">£4,750,000</option>
                              <option :value="5000000">£5,000,000</option>
                              <option :value="7500000">£7,500,000</option>
                              <option :value="10000000">£10,000,000</option>
                              <option :value="15000000">£15,000,000</option>
                              <option :value="20000000">£20,000,000</option>
                            </select>
                          </div>
                          <div class="col-6 m-0 p-0 ps-1">
                            <select
                                v-model="searchForm.maximum"
                                class="select-placeholder filter-select cursor-pointer"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(searchForm.maximum, $event)"
                                tabindex="-1"
                                aria-label="maximum"
                                name="maximum"
                                id="maximum"
                            >
                              <option value="" disabled>Maximum Loan</option>
                              <option :value="0">£0</option>
                              <option :value="50">£50</option>
                              <option :value="100">£100</option>
                              <option :value="150">£150</option>
                              <option :value="200">£200</option>
                              <option :value="250">£250</option>
                              <option :value="500">£500</option>
                              <option :value="750">£750</option>
                              <option :value="1000">£1,000</option>
                              <option :value="1250">£1,250</option>
                              <option :value="1500">£1,500</option>
                              <option :value="1750">£1,750</option>
                              <option :value="2000">£2,000</option>
                              <option :value="3000">£3,000</option>
                              <option :value="4000">£4,000</option>
                              <option :value="5000">£5,000</option>
                              <option :value="10000">£10,000</option>
                              <option :value="20000">£20,000</option>
                              <option :value="30000">£30,000</option>
                              <option :value="40000">£40,000</option>
                              <option :value="50000">£50,000</option>
                              <option :value="60000">£60,000</option>
                              <option :value="70000">£70,000</option>
                              <option :value="80000">£80,000</option>
                              <option :value="90000">£90,000</option>
                              <option :value="100000">£100,000</option>
                              <option :value="110000">£110,000</option>
                              <option :value="120000">£120,000</option>
                              <option :value="130000">£130,000</option>
                              <option :value="140000">£140,000</option>
                              <option :value="150000">£150,000</option>
                              <option :value="160000">£160,000</option>
                              <option :value="170000">£170,000</option>
                              <option :value="180000">£180,000</option>
                              <option :value="190000">£190,000</option>
                              <option :value="200000">£200,000</option>
                              <option :value="210000">£210,000</option>
                              <option :value="220000">£220,000</option>
                              <option :value="230000">£230,000</option>
                              <option :value="240000">£240,000</option>
                              <option :value="250000">£250,000</option>
                              <option :value="260000">£260,000</option>
                              <option :value="270000">£270,000</option>
                              <option :value="280000">£280,000</option>
                              <option :value="290000">£290,000</option>
                              <option :value="300000">£300,000</option>
                              <option :value="310000">£310,000</option>
                              <option :value="320000">£320,000</option>
                              <option :value="330000">£330,000</option>
                              <option :value="340000">£340,000</option>
                              <option :value="350000">£350,000</option>
                              <option :value="360000">£360,000</option>
                              <option :value="370000">£370,000</option>
                              <option :value="380000">£380,000</option>
                              <option :value="390000">£390,000</option>
                              <option :value="400000">£400,000</option>
                              <option :value="410000">£410,000</option>
                              <option :value="420000">£420,000</option>
                              <option :value="430000">£430,000</option>
                              <option :value="440000">£440,000</option>
                              <option :value="450000">£450,000</option>
                              <option :value="460000">£460,000</option>
                              <option :value="470000">£470,000</option>
                              <option :value="480000">£480,000</option>
                              <option :value="490000">£490,000</option>
                              <option :value="500000">£500,000</option>
                              <option :value="550000">£550,000</option>
                              <option :value="600000">£600,000</option>
                              <option :value="650000">£650,000</option>
                              <option :value="700000">£700,000</option>
                              <option :value="750000">£750,000</option>
                              <option :value="800000">£800,000</option>
                              <option :value="850000">£850,000</option>
                              <option :value="900000">£900,000</option>
                              <option :value="950000">£950,000</option>
                              <option :value="1000000">£1,000,000</option>
                              <option :value="1250000">£1,250,000</option>
                              <option :value="1500000">£1,500,000</option>
                              <option :value="1750000">£1,750,000</option>
                              <option :value="2000000">£2,000,000</option>
                              <option :value="2250000">£2,250,000</option>
                              <option :value="2500000">£2,500,000</option>
                              <option :value="2750000">£2,750,000</option>
                              <option :value="3000000">£3,000,000</option>
                              <option :value="3250000">£3,250,000</option>
                              <option :value="3500000">£3,500,000</option>
                              <option :value="3750000">£3,750,000</option>
                              <option :value="4000000">£4,000,000</option>
                              <option :value="4250000">£4,250,000</option>
                              <option :value="4500000">£4,500,000</option>
                              <option :value="4750000">£4,750,000</option>
                              <option :value="5000000">£5,000,000</option>
                              <option :value="7500000">£7,500,000</option>
                              <option :value="10000000">£10,000,000</option>
                              <option :value="15000000">£15,000,000</option>
                              <option :value="20000000">£20,000,000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

<!--                &lt;!&ndash; Start Horizontal Line with Icon &ndash;&gt;-->
<!--                <div class="horizontal-separator-box">-->
<!--                  <span class="hr-line-short"></span>-->
<!--                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>-->
<!--                  <span class="hr-line-short"></span>-->
<!--                </div>-->
<!--                &lt;!&ndash; End Horizontal Line with Icon &ndash;&gt;-->

                <!-- Bathroom & Downstairs WC -->
                <div v-if="searchForm.ad_type" class="row m-0 p-0">
                  <div class="col-12 p-0 mt-1 mb-1">
                    <div class="row m-0 p-0">
                      <!-- Bathrooms -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-4 col-sm-8 col-md-8 col-lg-4">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Bathrooms</p>
                            <font-awesome-icon icon="bath" class="icon-fa" />
                          </div>
                          <div class="col-8 col-sm-4 col-md-4 col-lg-8">
                            <select
                                v-model="searchForm.bathrooms"
                                class="select-placeholder filter-select cursor-pointer"
                                @focus="handleFocus($event)"
                                @focusout="handleFocusOut(searchForm.bathrooms, $event)"
                                tabindex="-1"
                                aria-label="bathrooms"
                                name="bathrooms"
                                id="bathrooms"
                            >
                              <option value="" disabled>...</option>
                              <option :value="1">1</option>
                              <option :value="2">2</option>
                              <option :value="3">3</option>
                              <option :value="4">4</option>
                              <option :value="5">5+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <!-- Downstairs WC -->
                      <div class="col-6 p-0">
                        <div class="row m-0 p-0">
                          <div class="col-6 col-sm-8 col-md-8 col-lg-6">
                            <p class="options-paragraph m-0 p-0 me-2 d-inline">Downstairs WC</p>
                            <font-awesome-icon icon="toilet" class="icon-fa" />
                          </div>
                          <div class="col-6 col-sm-4 col-md-4 col-lg-6">
                            <label class="switch">
                              <input type="checkbox" v-model="searchForm.is_downstairs_wc">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Start Horizontal Line with Icon -->
                <div v-if="searchForm.ad_type" class="horizontal-separator-box">
                  <span class="hr-line-short"></span>
                  <span aria-hidden="true"><font-awesome-icon icon="sun" class="horizontal-separator-icon"/></span>
                  <span class="hr-line-short"></span>
                </div>
                <!-- End Horizontal Line with Icon -->

              </div>
            </form>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <div id="searchServiceButton">
      <button id="search-service-component" :class="['btn btn-outline-primary btn-round z-index-special ', {active: activeServiceSearch}]" v-on:click="activeServiceSearch = !activeServiceSearch"><font-awesome-icon icon="search" class="ms-0" /></button>
    </div>
<!--    <div id="searchServiceButton">-->
<!--      <button id="search-service-component" :class="['btn btn-outline-primary btn-round z-index-special ', {active: activeServiceSearch}]" v-on:click="activeServiceSearch = !activeServiceSearch"><font-awesome-icon icon="search" class="ms-0" /></button>-->
<!--    </div>-->
  </div>
</template>

<script>
import axios from "axios";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import {mapActions} from "vuex";

export default {
  name:"SearchServiceFilters",
  data(){
    return {
      user: this.$store.state.auth.user,
      activeServiceSearch: true,
      activeServiceFilters: false,
      searchForm: {
        /* Sort By */
        newest: false,
        oldest: false,
        highest_price: false,
        lowest_price: false,
        occupation: '',
        /* Address and radius */
        address: '',
        line_1: '',
        line_2: '',
        street: '',
        postcode: '',
        city: '',
        county: '',
        boundary_box: '',
        longitude: '',
        latitude: '',
        /* Price */
        service_payment_type_id: '',
        min_price: '',
        max_price: '',
        price: 'Price',
        checked: false,
        drawbboxs: ''
      },
      serviceTypeOccupationsOptions: [],
      noFiltersForOccupation: true
    }
  },
  async mounted() {
    await this.getServiceTypeOccupations();

    this.$root.$on('setServicesFilters', (searchServicesFilters) => {
      this.setSearchServicesFilters(searchServicesFilters);
    });

    this.initGeocodeBoxServicesFilters();
    if(this.$store.state.searchServicesFilters.searchServicesFilters !== false) {
      this.searchForm = {...this.$store.state.searchServicesFilters.searchServicesFilters};
    }

    // this.setSearchServicesFilters(this.searchForm);
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('setServicesFilters');
  },
  methods:{
    getServiceTypeOccupations: async function () {
      let headers = {
        'Retry-After': 3
      }
      return axios.post('api/service-type-occupations', [], {headers: headers}).then(({data}) => {
        this.serviceTypeOccupationsOptions = data;
      })
    },
    handleFocus(event) {
      if(!event.target.classList.contains('select-clicked')) {
        event.target.classList.toggle('select-clicked');
      }
    },
    handleFocusOut(value, event) {
      if(value === null || value === '') {
        if(event.target.classList.contains('select-clicked')) {
          event.target.classList.toggle('select-clicked');
        }
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    ...mapActions({
      setSearchServicesFilters:'searchServicesFilters/setSearchServicesFilters'
    }),
    searchServices() {
      // close #openFilter window, so it does not cover visibility after search
      this.closeFilters();
      // this.setSearchServicesFilters(this.searchForm);
      // Perform new search
      this.$emit('refreshServices', this.searchForm);
    },
    openSearchBox() {
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        document.getElementById("searchServiceModal").style.transition = "all 0.5s";
        document.getElementById("searchServiceModal").style.height = "auto"; // 11vh
        document.getElementById("searchServiceModal").style.visibility = "visible";
        // document.getElementById("searchServiceModal").style.border = "border: 0px solid rgba(0, 0, 0, 0.125)";
        document.getElementById("searchServiceModal").style.boxShadow = "0 0.5rem 1rem rgb(0 0 0 / 15%)";
        document.getElementById("searchServiceModal").style.opacity = "1";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchServiceModal").style.overflow = "visible";
              clearTimeout(timeout);
            }, 700);
        // check if element exists
        let mapboxInput = document.getElementsByClassName("mapboxgl-ctrl-geocoder--input");
        if(mapboxInput[0]) {
          document.getElementsByClassName("mapboxgl-ctrl-geocoder--input")[0].focus();
        }
        //border: 0px solid rgba(0, 0, 0, 0.125);
        //box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      }
      if(this.activeServiceFilters) {
        document.getElementById("searchServiceModal").style.height = "60vh";
      }
    },
    closeSearchBox() {
      if(document.body.contains(document.getElementById("openServiceFilters"))) {
        // document.getElementById("openServiceFilters").style.height = "0";
        // document.getElementById("filters-service-form").style.opacity = "0";
        // this.activeServiceFilters = !this.activeServiceFilters;
      }
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        document.getElementById("searchServiceModal").style.transition = "all 0.5s";
        // document.getElementById("searchServiceModal").style.height = "0";
        document.getElementById("searchServiceModal").style.visibility = "hidden";
        document.getElementById("searchServiceModal").style.border = "none";
        document.getElementById("searchServiceModal").style.boxShadow = "none";
        document.getElementById("searchServiceModal").style.opacity = "0";

        const timeout = setTimeout(
            function() {
              document.getElementById("searchServiceModal").style.overflow = "hidden";
              clearTimeout(timeout);
            }, 700);
      }
    },
    openServiceFilters() {
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        let serviceFilterIcon = document.getElementById("serviceFilterIcon");
        if (!serviceFilterIcon.classList.contains("rotate180")) {
          serviceFilterIcon.classList.toggle("rotate180");
        }
        // Create a media condition that targets viewports of max-width 575px
        const mediaQueryMax575 = window.matchMedia('(max-width: 575px)')
        // Check if the media query is true
        if (mediaQueryMax575.matches) {
          document.getElementById("searchServiceModal").style.height = "80vh";
        } else {
          document.getElementById("searchServiceModal").style.height = "60vh";
        }
      }

      const timeout = setTimeout(
          function() {
            if(document.body.contains(document.getElementById("openServiceFilters"))) {
              document.getElementById("filters-service-form").style.transition = "all 0.5s";
              document.getElementById("openServiceFilters").style.height = "60vh";
              document.getElementById("filters-service-form").style.opacity = "1";
            }
            clearTimeout(timeout);
          }, 600);
    },
    closeFilters() {
      if(document.body.contains(document.getElementById("searchServiceModal"))) {
        document.getElementById("searchServiceModal").style.height = "auto"; // 11vh
        let serviceFilterIcon = document.getElementById("serviceFilterIcon");
        if (serviceFilterIcon.classList.contains("rotate180")) {
          serviceFilterIcon.classList.toggle("rotate180");
        }
      }
      if(document.body.contains(document.getElementById("openServiceFilters"))) {
        document.getElementById("filters-service-form").style.transition = "all 0.1s";
        document.getElementById("filters-service-form").style.opacity = "0";
        document.getElementById("openServiceFilters").style.transition = "all 0.5s";
        document.getElementById("openServiceFilters").style.height = "0";
        document.getElementById("openServiceFilters").style.opacity = "0";
        if(this.activeServiceFilters === true) {
          this.activeServiceFilters = !this.activeServiceFilters;
        }
      }
    },
    initGeocodeBoxServicesFilters(type) {
      this.$nextTick(() => {

        // Mapbox Geocoding Address Autocomplete
        let geocoderSearchServiceFilters = '';

        // We want to search only by locality if thats possible
        geocoderSearchServiceFilters = new MapboxGeocoder({
          accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
          types: 'region,place,district,postcode,locality',
          limit: 7,
          countries: 'gb, ie, es', // Restrict to UK,Ireland and Spain only
        });

        let geoCheck = document.getElementById('geocoderSearchServiceFilters');
        if (geoCheck) {
          if(geoCheck.children.length <= 0) {
            geocoderSearchServiceFilters.addTo('#geocoderSearchServiceFilters');
          }
        }

        // #addressPrepopulated if exists in searchServicesFilters
        if(this.$store.state.searchServicesFilters.searchServicesFilters && this.$store.state.searchServicesFilters.searchServicesFilters.address) {
          // Later I will use this solution, I am awaiting for second parameter to be added
          // https://github.com/mapbox/mapbox-gl-geocoder/pull/425
          // geocoderSearchServiceFilters.setInput(this.$store.state.searchServicesFilters.searchServicesFilters.address);
          geocoderSearchServiceFilters._inputEl.value = this.$store.state.searchServicesFilters.searchServicesFilters.address;
          // geocoderSearchServiceFilters.query(this.$store.state.searchServicesFilters.searchServicesFilters.address);
          // I need to emit some click or focus so it does not focus on geocoderSearchServiceFilters...
          // It does not work no matter what I do... I need to work it out... damn
          this.$nextTick(() => {
            // let suggestions = document.getElementById('geocoderSearchServiceFilters');
            // suggestions.focus();
            // suggestions.blur();
            // suggestions[0].style.display = 'none';
            // suggestions[0].focus();
            // suggestions[0].blur();
          })
        }

        // Add geocoderSearchServiceFilters result to container.
        geocoderSearchServiceFilters.on('result', (e) => {
          // console.log(e);
          // results.innerText = JSON.stringify(e.result, null, 2);

          // Reset all values before new are assigned
          this.searchForm.address = '';
          this.searchForm.longitude = '';
          this.searchForm.latitude = '';
          this.searchForm.boundary_box = '';
          this.searchForm.line_1 = '';
          this.searchForm.line_2 = '';
          this.searchForm.street = '';
          this.searchForm.postcode = '';
          this.searchForm.city = '';
          this.searchForm.county = '';

          // For districts such as South Yorkshire, Hampshire etc.

          // Highly likely I wont need that on searchServicesFilters
          if(e.result.place_type.includes('district')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // district
            if (e.result.text) {
              this.searchForm.county = e.result.text;
            }
            // For places, which are cities, such as Manchester, Leeds etc.
          } else if (e.result.place_type.includes('place')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Place - City
            if (e.result.text) {
              this.searchForm.city = e.result.text;
            }
          } else if (e.result.place_type.includes('postcode')) {
            for(let i=0; i < e.result.context.length; i++) {
              if(e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Postcode
            if(e.result.text) {
              this.searchForm.postcode = e.result.text;
            }
            // For locality such as Cudworth, Cosham etc.
          } else if (e.result.place_type.includes('locality')) {
            for (let i = 0; i < e.result.context.length; i++) {
              if (e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if (e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // Locality
            if (e.result.text) {
              this.searchForm.line_2 = e.result.text;
            }
            // For addresses, detailed ones such as 7 California Street etc. and all the rest
          } else {
            for(let i=0; i < e.result.context.length; i++) {
              if(e.result.context[i].id.includes('postcode')) {
                this.searchForm.postcode = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('locality')) {
                this.searchForm.line_2 = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('place')) {
                this.searchForm.city = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('district')) {
                this.searchForm.county = e.result.context[i].text;
              }
              if(e.result.context[i].id.includes('country')) {
                this.searchForm.country = e.result.context[i].text;
              }
            }
            // First line of address
            if(e.result.text || e.result.address) {
              this.searchForm.line_1 = (e.result.address ? e.result.address + ' ' : '') + (e.result.text ? e.result.text : '');
            }
            if(e.result.text) {
              this.searchForm.street = (e.result.text ? e.result.text : '');
            }
          }

          // Full Address
          if(e.result.place_name) {
            this.searchForm.address = e.result.place_name;
          }
          // Coordinates
          if(e.result.center) {
            this.searchForm.longitude = e.result.center[0];
            this.searchForm.latitude = e.result.center[1];
          }
          // Boundary Box
          if(e.result.bbox) {
            this.searchForm.boundary_box = e.result.bbox;
          }
        });

        // Clear results container when search is cleared.
        geocoderSearchServiceFilters.on('clear', () => {
          // results.innerText = '';
          this.searchForm.address = '';
          this.searchForm.longitude = '';
          this.searchForm.latitude = '';
          this.searchForm.boundary_box = '';
          this.searchForm.line_1 = '';
          this.searchForm.line_2 = '';
          this.searchForm.street = '';
          this.searchForm.postcode = '';
          this.searchForm.city = '';
          this.searchForm.county = '';
        });

      });
    }
  },
  created() {
    // this.activeServiceSearch = !this.activeServiceSearch;

    // // Search properties
    // axios.get('api/properties',this.properties).then(({data})=>{
    //   this.properties = data['data']
    // }).catch(({response:{data}})=>{
    //   alert(data.message)
    // }).finally(()=>{
    //   this.processing = false
    // })
  },
  watch: {
    'activeServiceSearch': function (val) {
      if(this.activeServiceSearch === true) {
        this.openSearchBox();
      } else {
        this.closeSearchBox();
      }
    },
    'activeServiceFilters': function (val) {
      if(this.activeServiceFilters === true) {
        this.openServiceFilters();
      } else {
        this.closeFilters();
      }
    },
    'searchForm': {
      handler: function (val) {
        // console.log(JSON.stringify(this.searchForm) === JSON.stringify(this.$store.state.searchServicesFilters.searchServicesFilters));
        let searchBtn = document.getElementById("searchServicesFiltersButton");
        if (searchBtn && !searchBtn.classList.contains("search-service-filter-button") && JSON.stringify(this.searchForm) !== JSON.stringify(this.$store.state.searchServicesFilters.searchServicesFilters)) {
          searchBtn.classList.toggle("search-service-filter-button");
        }
        if (searchBtn && JSON.stringify(this.searchForm) === JSON.stringify(this.$store.state.searchServicesFilters.searchServicesFilters)) {
          if (searchBtn.classList.contains("search-service-filter-button")) {
            searchBtn.classList.toggle("search-service-filter-button");
          }
        }
      }, deep: true
    },
    'searchForm.occupation': function (val) {
      this.noFiltersForOccupation = true;

      if (val && val.type === 'Lender') {
        this.noFiltersForOccupation = false;
      }
    },
    'searchForm.min_price': function (val) {
      this.searchForm.price = '£' + val + (this.searchForm.max_price !== '' ? ' - £' + this.searchForm.max_price : '');
    },
    'searchForm.max_price': function (val) {
      this.searchForm.price = (this.searchForm.min_price !== '' ? '£' + this.searchForm.min_price : '') + ' - £' + val;
    },
    '$store.state.searchServicesFilters.searchServicesFilters': function (val) {
      this.searchForm = {...this.$store.state.searchServicesFilters.searchServicesFilters};
    },
    'sortBy': function(val) {
      if (this.$store.state.searchServicesFilters.searchServicesFilters) {

        this.searchForm = {...this.$store.state.searchServicesFilters.searchServicesFilters};

        switch(val) {
          case 1:
            this.searchForm.newest = true;
            this.searchForm.oldest = false;
            this.searchForm.lowest_price = false;
            this.searchForm.highest_price = false;
            break;
          case 2:
            this.searchForm.newest = false;
            this.searchForm.oldest = true;
            this.searchForm.lowest_price = false;
            this.searchForm.highest_price = false;
            break;
          case 3:
            this.searchForm.newest = false;
            this.searchForm.oldest = false;
            this.searchForm.lowest_price = true;
            this.searchForm.highest_price = false;
            break;
          case 4:
            this.searchForm.newest = false;
            this.searchForm.oldest = false;
            this.searchForm.lowest_price = false;
            this.searchForm.highest_price = true;
            break;
        }

        // Set searchServicesFilters
        this.$root.$emit('setServicesFilters', this.searchForm);
        // Perform new search
        this.$emit('refreshServices', this.searchForm);
      }
    }
  }
}
</script>

<style lang="scss">

/* Map Box Geocoder */
#geocoder {
  z-index: 1;
}
.mapboxgl-ctrl-geocoder {
  border-radius: .25rem 0 0 .25rem;
  //border: 1px solid #ced4da;
  width: 100%;
  box-shadow: none;
  min-width: 100%;
}

/* Input box on focus */
.mapboxgl-ctrl-geocoder--input:focus {
  border-radius: 5px;
}

/* Search Results size */
.mapboxgl-ctrl-geocoder .suggestions li .mapboxgl-ctrl-geocoder--suggestion {
  font-size: 12px !important;
}
/* End Map Box Geocoder */

/* Fade effect for transition elements */
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.openServiceFilters {
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px 1px 10px 10px;
  margin-top: 10px;
  font-size: 14px;
}

#filters-service-form {
  opacity: 0;
}
/* Important so that searchServicesFilters are up-front when focused */
#searchServiceModal:focus {
  z-index: 99999 !important;
}

/* Important so that searchServicesFilters are up-front when focused */
#searchServiceModal:focus-within {
  z-index: 99999 !important;
}

.btn-add {
  white-space: nowrap;
}

.btn-add .add-icon {
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.btn-add:hover .add-icon {
  transform: rotate(90deg);
  transition: 0.5s;
  -moz-transition: 0.5s;
}

.dropdown-btn {
  color: #212529;
  background-color: #fff;
}

.search-service-bar * {
  //border-radius: 0;
  border: none !important;
  outline: 0;
  box-shadow: none;
}

.search-service-bar * :focus {
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

.search-filter-btns {
  border-radius: 0;
  border: none;
  outline: 0;
  box-shadow: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.search-filter-btns:focus {
  border-radius: 5px !important;
  color: rgba(0, 0, 0, 0.75) !important;
  outline: 0;
  box-shadow: none;
  outline: thin dotted !important;
}

/* dropdown buttons in filters */
.dropdown-container-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown-menu {
  //position: absolute;
  //width: 220px;
  //top: 100%;
  //left: -20% !important;
}

.form-position {
  position: relative;
}

.dropdown-menu-filters {
  position: absolute;
  width: 220px;
  top: 100%;
}

.filter-select {
  border-radius: 10px;
  border: 1px solid #9e9e9e;
  background-color: white;
  width: 100%;
  padding: 5px;
}

.dropdown-menu-prop-type {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 450px;
}

.filter-prop-label {
  background-color: #eeeeee;
  border-radius: 10px;
}

/* Start Search Service Filters Expand Filters Button - Double Chevron */
.rotate180 {
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  transform: rotate(180deg);
}

.btn-round-search-filters {
  border-radius: 0 0 50% 50% !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  border-top: none;
  border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125) ;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

#serviceFiltersButton {
  background-color: white;
  //background-color: #fcfcfc;
  transition: 0.7s;
  -webkit-transition-duration: 0.7s;
  position: absolute;
  bottom: -17px;
  //left: 48.3%;
  left: 48%;
  right: 48%;
  width: 30px;
  height: auto;
  //transform: translate(-50%);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s;
  animation-iteration-count: 2;
}
#serviceFiltersButton:hover {
  color: red;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* End Search Service Filters Expand Filters Button - Double Chevron */

/* Search Button Animation */
.search-service-filter-button {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}
/* End Search Button Animation */

.btn-round {
  border-radius: 50% !important;
}

.z-index-special {
  z-index: 9999 !important;
}

/* .btn RAF CHANGE */
#searchServiceModal .btn {
  border: 0 !important;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: none !important;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: none !important;
}

.border-none {
  border: none;
}

.border-lr {
  border: none;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.border-left {
  border: none;
  border-left: 1px solid transparent;
}

.border-right {
  border: none;
  border-right: 1px solid transparent;
}

#searchServiceButton {
  //position: absolute;
  transition: margin-left .5s;
  display: none;
}

//#searchServiceButton {
//  position: absolute;
//  transition: margin-left .5s;
//  padding: 16px;
//}

/* search bar */
.search-service-component {
  width: 100%;
  position: relative;
  z-index: 100;
  //top: 11vh;
  //left: 0;
  //transform: translate(-50%);
  background-color: rgba(255, 255, 255, 90%);
  overflow: visible;
  opacity: 1;
  transition: 0.5s;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.125);
  margin: 5px 0 5px 0;
}

.search-service-component a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.search-service-component a:hover {
  color: #f1f1f1;
}

.search-service-component .closebtn {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtnLeft {
  position: relative;
  top: 0;
  left: 0;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(33, 150, 243, 50%);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 0 50% 50% 0;
}

.openbtnLeft:hover {
  background-color: #444;
}

/* ===== Toggles ===== */
/* The switch - the box around the slider */
.switch {
  position: relative;
  text-align: center;
  display: inline-block;
  line-height: 20px;
  -moz-block-height: 20px;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* End Slider */
/* ===== End Toggles ===== */

/* ===== Start Placeholders in search filters ===== */
.searchformfld{
  position: relative;
  margin: 5px 0px;
}
.searchformfld label{
  position: absolute;
  padding-left: 10px;
  top:15px;
  cursor: text;

}
.searchformfld input:focus + label,.searchformfld input:not(:placeholder-shown) + label{
  opacity:1;
  transform: scale(.9) translateY(-100%) translateX(-10px);
  color:#000;
}
.searchformfld input:focus{
  border:1px solid #000;
  outline-color: #000;
}
.searchformfld{
  padding: 15px;
  margin:15px 0px;
}
.searchformfld input{
  width:100%;
  padding-left: 10px;
}
.searchformfld label,.searchformfld input{
  transition: all 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity:0.5;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .search-service-component {}
  .search-service-component a {font-size: 18px;}
}
@media screen and (max-width: 575px) {
  .search-service-component .mapboxgl-ctrl-geocoder {
    border-radius: .25rem .25rem 0 0 !important;
  }
  .search-service-component .border-radius-r-5 {
    border-radius: 0 0 .25rem .25rem !important;
  }
  .search-service-component .search-filter-btns:focus {
    border-radius: 2px !important;
  }
  .border-lr {
    border: none !important;
    border-left: none !important;
    border-right: none !important;
  }
  .border-left {
    border: none !important;
    border-left: none !important;
  }
  .border-right {
    border: none !important;
    border-right: none !important;
  }
}
@media screen and (min-width: 576px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}
@media screen and (min-width: 1920px) {
  .border-none {
    border: none !important;
  }
  .border-lr {
    border: none !important;
    border-left: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
  }
  .border-left {
    border: none !important;
    border-left: 1px solid transparent !important;
  }
  .border-right {
    border: none !important;
    border-right: 1px solid transparent !important;
  }
}

</style>
